import config from 'config'
import { useStoreContext } from './useStoreContext'
import { useStoreIndentity } from './useStoreIdentity'

export const useStoreLang = (): string => {
  const { defaultLangCode } = useStoreContext() //From store
  const { langCode } = useStoreIndentity() //From url
  const { fallbackLanguage } = config

  return defaultLangCode || langCode || fallbackLanguage
}
