import { IconProps } from 'types/icons'
import React from 'react'

export const StopButton: React.FC<IconProps> = ({ width = '1rem', height = '1rem' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="#333333" />
      <g clipPath="url(#clip0_4154_4620)">
        <path
          d="M30 12C20.0753 12 12 20.0753 12 30C12 39.9248 20.0753 48 30 48C39.9248 48 48 39.9248 48 30C48 20.0753 39.9248 12 30 12ZM30 46.5C20.9018 46.5 13.5 39.0983 13.5 30C13.5 20.9018 20.9018 13.5 30 13.5C39.0983 13.5 46.5 20.9018 46.5 30C46.5 39.0983 39.0983 46.5 30 46.5Z"
          fill="white"
        />
        <path d="M26.25 22.5H24.75V37.5H26.25V22.5Z" fill="white" />
        <path d="M35.25 22.5H33.75V37.5H35.25V22.5Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_4154_4620">
          <rect width="36" height="36" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  )
}
