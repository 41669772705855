import { pxToRem } from 'libs/styled'
import { createGlobalStyle, css } from 'styled-components'
import { fonts } from './fonts'

export const GlobalStyle = createGlobalStyle`
  #root,
  .vk-panel,
  main.content,

  :root {
  --base-primary: ${({ theme }) => theme.cta.backgroundColor} !important;
  --base-white: ${({ theme }) => theme.cta.textColor} !important;
  }

  .base-layout {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.bodyBackground};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 400;
  }

  ${fonts}

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.primaryText};
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    overflow: hidden;
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  ::-webkit-scrollbar {
    display: none;
  }

  /* override erroneous antd computation with rem */
  a.ant-btn.ant-btn {
    line-height: 2.109rem;
  }

  .ant-modal-root,
  .ant-modal-wrap {
    overscroll-behavior: none !important;
  }

  .ant-modal-root {
    .ant-modal-body {
      padding: 1.636rem;
    }

    .ant-modal-content {
      border-radius: 0;
    }

    .ant-modal-close {
      top: 1.364rem;
      right: 1.364rem;
    }

    .ant-modal-close-x {
      width: 1.455rem;
      height: 1.455rem;
    }
  }

  .ant-select-dropdown {
    box-shadow: none !important;
    border: 1px solid #e2e2e2;
    border-radius: 0 !important;
  }

  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-selected {
    font-size: 0.591rem !important;
    font-weight: normal !important;
    background-color: white !important;
  }

  #bipa-overlay-root-style.bipa-overlay #bipa-component-container{
        max-width: 84vw !important;
        padding-top: ${pxToRem(45)} !important;
      }

  #bipa-overlay-root-style.bipa-overlay .scrollable-container {
        max-height: calc(100vh - ${pxToRem(90)});
      }

  @media screen and (min-width: 720px) and (max-width: 1080px) {
    html {
      font-size: calc(100vw * (6 / 360) + 4px);
    }
  }
  @media screen and (min-width: 1000px) {
    html {
      font-size: 22px;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1195px) and (orientation: landscape) {
    html {
      font-size: calc(100vh * (6 / 360) + 4px);
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

    // Terms and conditions modal
  .terms-and-conditions, .couvette-modal  {padding-bottom: 0 !important;}

  .couvette-modal .ant-modal-body {
    height: 100% !important;
  }

  // Prevent iOS elastic scroll
  .no-overscroll {
    overscroll-behavior: none;
    overflow: hidden !important;
  }

  .search-drawer {
    z-index: 5 !important;
  }

  .Text-lgBold {
    font-family: ${({ theme }) => theme.fonts.primary} !important;
  }

  .Text-lgRegular {
    #rtr-error-button {
      font-family: ${({ theme }) => theme.fonts.primary} !important;
      background-color: ${({ theme }) => theme.cta.backgroundColor} !important;
      color: ${({ theme }) => theme.cta.textColor} !important;
      border-radius: ${({ theme }) => theme.cta.borderRadius} !important;
      font-weight: bold !important;
      font-size: 0.91rem !important;
    }
  }
`

export const scrollbar = css`
  ::-webkit-scrollbar {
    display: initial !important;
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.electronics.colors.primary};
    border-radius: 5px;
  }
`
